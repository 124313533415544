import React from 'react'

import ErrorFallback from './components/errorFallback'

import * as Sentry from '@sentry/react'
import { each } from 'lodash-es'
import ToastDisplay from 'r/components/toastDisplay'
import 'react-dates/initialize'
import { createRoot } from 'react-dom/client'

let mountedNodeRoots = []

let mountables = {}
export function registerComponent(name, importFunction) {
  mountables[name] = importFunction
}

document.addEventListener('turbolinks:load', () => {
  each(document.querySelectorAll('[data-react-component]'), (node) => {
    const importFunction = mountables[node.dataset.reactComponent]

    if (importFunction) {
      importFunction().then(({ default: Component }) => {
        const props = JSON.parse(node.dataset.reactProps || '{}')
        const root = createRoot(node)

        root.render(
          <Sentry.ErrorBoundary fallback={ErrorFallback}>
            <ToastDisplay>
              <Component {...props} />
            </ToastDisplay>
          </Sentry.ErrorBoundary>
        )

        mountedNodeRoots.push(root)
      })
    }
  })
})

document.addEventListener('turbolinks:before-render', () => {
  while (mountedNodeRoots.length) {
    mountedNodeRoots.pop().unmount()
  }
})
